@import './variables';

.form-container {
    background-color: $color-1;
    border-radius: 1rem;
    // margin: 0 0 2rem 0;
}

.contact-headshot-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contact-headshot {
    width: 350px;
    height: 400px;
    object-fit: cover;
    object-position: 0px 2%;
    border-radius: 1.5rem;
    @media only screen and (max-width: $sm) {
        width: 90%;
    }
}

.form-body {
    margin: 2rem;
}

.contact-block {
    border-radius: 1rem;
    background-color: $color-2;
    width: $lg;
    padding: 1rem 0 1rem 0;
    @media only screen and (max-width: $lg) {
        width: 90%;
    }
}