.footer-container{
    margin-top: 2rem;
    padding: 2rem 0rem 3rem 0rem;
    display: flex;
    justify-content: center;
    background-color: black;
}

footer {
    color: white;
}

.footer-banner {
    height: auto;
    width: 80%;
}