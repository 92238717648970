@import "./variables";

.feature-thumbnail {
    object-fit: cover;
    height: 216px;
    width: 400px;
}

.feature-container {
    padding: 1rem;
    margin: 2rem;
    background-color: $color-1;
    width: 80%;
}

.feature-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.production-link {
    margin-right: 1rem;
}
