.art {
    width: auto;
    height: 15rem;
    border-radius: 1rem;
    // border: 3px solid black;
    margin: 1rem;

    @media only screen and (max-width: $md) {
        width: 90%;
        height: auto;
    }
}