@import "./variables";

.headshot {
    $headshot-size: 360px;
    width: 90%;
    height: $headshot-size;
    object-fit: cover;
    object-position: 0px 2%;
    border-radius: 1.5rem;
    @media only screen and (max-width: $md) {
        width: 100%;
    }
}

.grid-image {
    $dimension: 200px;
    border-radius: 1.5rem;
    height: $dimension;
    width: $dimension;
    object-fit: cover;
}

.grid-image-container {
    height: 280px;
    width: 280px;
    object-fit: cover;
}

.grid-overlay-shadow {
    width: 100%;
    height: 25%;
    position: absolute;
    background-color: black;
    opacity: 50%;
    bottom: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 1.5rem 1.5rem;
}

.grid-overlay-text {
    color: white;
    opacity: 100%;
}

.homepage-grid-container {
    margin-bottom: 2rem;
    background-color: $color-2;
    border-radius: 1.5rem;
    filter: drop-shadow(1rem 1rem 1rem);
    @media only screen and (max-width: $xl) {
        $dimension: 700px;
        width: $dimension;
        height: $dimension;
    }
    @media only screen and (max-width: $md) {
        width: 90%;
        height: fit-content;
    }
}

#bioAndLinks {
    border-radius: 1rem;
    background-color: $color-1;
}

#bioAndLinks p {
    font-size: 20px;
}

.first-block-container {
    width: 90%;
}

.contact-me-container {
    background-color: $color-1;
    width: 300px;
    height: 100px;
    margin: 2rem;
}

